import SectionComponent from '@components/SectionComponent'

import React from 'react'
import Grid from '@components/ViewGridComponent'
import { Caption, Heading2 } from '@components/Typography'
import SpacerComponent from '@components/SpacerComponent'
import styled from 'styled-components'
import flowers from './images'
import { motion } from 'framer-motion'
import { FadeIn } from '@components/Animations'
import useIsMobile from '@hooks/useIsMobile'
interface InfoProps {}

const Info: React.FC<InfoProps> = () => {
  const isMobile = useIsMobile()
  return (
    <Section>
      <Grid.Item columnStart={1} columnEnd={11}>
        <InfoP font='secondary'>
          <a href='mailto:hello@biglemon.co.uk'>hello@biglemon.co.uk </a>
        </InfoP>
        <Caption>Drop us a line</Caption>
        <SpacerComponent bottom='xl' />
        <InfoP font='secondary'>
          <a href='tel:+442921679025'>02921 679025 </a>
        </InfoP>

        <Caption>Call us</Caption>
        <SpacerComponent bottom='xl' />
        <Address font='secondary'>
          Big Lemon Creative Ltd, Britannia House, Caerphilly, CF83 3GG
        </Address>
        <Caption>Write to us</Caption>
      </Grid.Item>
      {!isMobile && (
        <FlowerContainer>
          <img src={flowers} alt='flower illustration' />
        </FlowerContainer>
      )}
    </Section>
  )
}

const Section = styled(SectionComponent)`
  position: relative;
  overflow: hidden;
`

const FlowerContainer = styled.div`
  position: absolute;
  bottom: 0px;
  overflow: hidden;
  right: 0;
  width: 33%;
  min-width: 416px;
`

const Address = styled(Heading2).attrs({ as: 'address' })`
  cursor: default;
`

const InfoP = styled(Heading2).attrs({ as: 'p' })`
  & a {
    text-decoration: none;
    color: inherit;
  }
`

export default Info
