import SeoComponent from '@components/SeoComponent'
import { darkPurpleTheme, paleBlueTheme } from '@src/themes'
import { ContactHero, Info } from '@src/views/ContactSections'
import React from 'react'
import { ThemeProvider } from 'styled-components'

interface ContactProps {}

const Contact: React.FC<ContactProps> = () => {
  return (
    <>
      <SeoComponent
        title='Big Lemon | Contact Us'
        description='Hello, there! Get in contact with the Big Lemon team to talk about your next project and to get a quote.'
      />
      <ThemeProvider theme={darkPurpleTheme}>
        <ContactHero />
      </ThemeProvider>
      <ThemeProvider theme={paleBlueTheme}>
        <Info />
      </ThemeProvider>
    </>
  )
}

export default Contact
