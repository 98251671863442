import React from 'react'

// Components
import FormContactComponent from '@components/FormContactComponent'
import SectionComponent from '@components/SectionComponent'
import SpacerComponent from '@components/SpacerComponent'
import { Paragraph, Title1, VerticalHeading } from '@components/Typography'
import Grid from '@components/ViewGridComponent'
import { FadeIn } from '@components/Animations'

type ContactHeroProps = {}

const ContactHero: React.FC<ContactHeroProps> = () => {
  return (
    <SectionComponent>
      <Grid.Item columnStart={1} rowStart={1} rowEnd={3} justifySelfStart>
        <FadeIn>
          <VerticalHeading hasVerticalSpacer>get in touch</VerticalHeading>
        </FadeIn>
      </Grid.Item>
      <Grid.Item columnStart={3} columnEnd={11}>
        <FadeIn>
          <Title1>Get In Touch</Title1>
          <SpacerComponent bottom='sm' />
          <Paragraph size='md'>
            If you’d like to find out if we’re a good fit for you, then fill out
            your details below and a member of our team will be in touch
            shortly.
          </Paragraph>
          <SpacerComponent bottom='xl' />

          <FormContactComponent />
        </FadeIn>
      </Grid.Item>
    </SectionComponent>
  )
}

export default ContactHero
